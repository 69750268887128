import React from 'react'
import get from 'lodash/get'
import Img from 'gatsby-image'
import LazyLoad from 'react-lazyload'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'

import {
  JsonLdOrganization,
  JsonLdWebSite,
  SitePostSummary,
  SEOMetaTags,
} from 'blog-components'

// eslint-disable-next-line
import SiteInformation from '../fragments/SiteInformationFragment'
// eslint-disable-next-line
import BlogPostDetail from '../fragments/BlogPostDetailFragment'
// eslint-disable-next-line
import ProductRoundDetail from '../fragments/ProductRoundUpDetailFragment'
// eslint-disable-next-line
import ShareImages from '../fragments/ShareImagesFragment'

import * as messages from '../messages.json'
import './style.scss'

class Home extends React.Component {
  constructor(props) {
    super(props)

    let mostPopularSlugs = new Set(
      this.props.data.mostPopular.edges[0].node.slugs.map(
        element => element.slug
      )
    )

    let mostPopularPosts = this.props.data.allContentfulBlogPost.posts.filter(
      element => mostPopularSlugs.has(element.post.slug)
    )

    this.state = { mostPopularPosts: mostPopularPosts }
  }

  buildOtherFeatureListItems() {
    let featureList = []

    let features = messages.pages.home.components.otherFeatures.features

    for (let i = 0; i < features.length; i++) {
      featureList.push(
        <li key={i} className="list-group-item">
          {features[i]}
        </li>
      )
    }

    return featureList
  }

  render() {
    const mostPopularPostsComponents = []
    const site = get(this, 'props.data.site')
    // const posts = get(this, 'props.data.allContentfulBlogPost.posts')
    // const roundups = get(this, 'props.data.allContentfulProductRoundUp.posts')
    const mostPopularPosts = get(this, 'state.mostPopularPosts')
    const smallHeroImage = get(this, 'props.data.hero.sml.fluid')
    const heroImage = get(this, 'props.data.hero.childImageSharp.fluid')

    // const combinedPosts = getPostAndRoundUpCombinedList(posts, roundups)

    mostPopularPosts.forEach((element, i) => {
      mostPopularPostsComponents.push(
        <LazyLoad height={500} offset={500} once={true} key={i}>
          <div className="col-md-4 p-3">
            <SitePostSummary
              title={element.post.title}
              slug={element.post.slug}
              shareImage={element.post.shareImage.fixed_1200x675}
              horizontal={true}
              key={i}
            />
          </div>
        </LazyLoad>
      )
    })

    let socialProfiles = [
      'https://www.twitter.com/' + site.siteMetadata.twitter,
      'https://www.facebook.com/' + site.siteMetadata.facebook,
      'https://www.instagram.com/' + site.siteMetadata.instagram,
      'https://www.pinterest.com/' + site.siteMetadata.pinterest,
    ]

    let jsonLdEntries = [
      JsonLdOrganization.generate(
        site.siteMetadata.brand,
        site.siteMetadata.siteUrl,
        socialProfiles,
        site.siteMetadata.siteUrl + '/main-logo.png',
        '700',
        '700'
      ),
      JsonLdWebSite.generate(
        site.siteMetadata.brand,
        site.siteMetadata.siteUrl,
        site.siteMetadata.siteUrl + '/main-logo.png',
        socialProfiles,
        messages.pages.home.metaData.description
      ),
    ]
    return (
      <Layout>
        <SEOMetaTags
          path="/"
          site={site}
          title={messages.pages.home.metaData.title}
          description={messages.pages.home.metaData.description}
          imageUrl={site.siteMetadata.siteUrl + smallHeroImage.src}
          structuredData={jsonLdEntries}
        />

        <div className="hero position-relative text-center">
          <Img
            fluid={heroImage}
            alt={messages.pages.home.components.hero.image.alt}
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              zIndex: -1,
            }}
          />
          <div className="row align-items-center no-gutters">
            <div className="col py-5">
              <h1 className="display-3 text-white">
                <span>{messages.pages.home.components.hero.image.tagline}</span>
              </h1>
            </div>
          </div>
        </div>

        <div className="container fadein-3" data-emergence="visible">
          <div className="row justify-content-md-center text-center pt-5 px-3 no-gutters">
            <div className="col-md-10">
              <h2 className="display-3 py-5">
                {messages.pages.home.components.mostPopular.heading}
              </h2>
            </div>
          </div>
          <div className="row justify-content-md-center text-center no-gutters">
            {mostPopularPostsComponents}
          </div>
        </div>
      </Layout>
    )
  }
}
export default Home
export const query = graphql`
  query HomeImageQuery {
    hero: file(relativePath: { eq: "mountain-scene-with-lake-in-front.jpg" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 4096) {
          ...GatsbyImageSharpFluid
        }
      }
      sml: childImageSharp {
        fluid(maxWidth: 1024) {
          src
        }
      }
    }
    site {
      ...SiteInformation
    }
    mostPopular: allMostPopularJson {
      edges {
        node {
          slugs {
            slug
          }
        }
      }
    }
    allContentfulBlogPost(
      filter: { visible: { eq: true } }
      sort: { order: DESC, fields: [publishDate] }
    ) {
      posts: edges {
        post: node {
          publishDateISO: publishDate
          title
          slug
          description {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          publishDate(formatString: "DD/MM/YYYY")
          updatedAt(formatString: "DD/MM/YYYY")
          shareImage {
            ...ShareImages
          }
          authors {
            slug
            name
          }
        }
      }
    }
    allContentfulProductRoundUp(
      filter: { visible: { eq: true } }
      sort: { order: DESC, fields: [publishDate] }
    ) {
      posts: edges {
        post: node {
          publishDateISO: publishDate
          title
          slug
          description {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          publishDate(formatString: "DD/MM/YYYY")
          updatedAt(formatString: "DD/MM/YYYY")
          shareImage {
            ...ShareImages
          }
          authors {
            slug
            name
          }
        }
      }
    }
  }
`
